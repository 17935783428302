.picking-article {
  display: grid;
  grid-template-columns: 1fr 0.75fr 0.75fr 2fr;
  padding: 0 1rem;
  margin-bottom: 1.5rem;

  &__article {
    font-weight: 600;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    button {
      margin-bottom: 0;
      padding: 0 1.5rem;
      width: 20rem;
    }

    .cancel-pickup-button {
      background-color: $softRed;
      border: none;
    }
  }

  &__miscellaneous {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: fit-content;

    .fas {
      margin: 0 0.2rem;

      &:not(.fa-check) {
        cursor: pointer;
      }
    }

    .fa-check {
      color: $green;
    }
    .fa-times {
      color: $red;
    }
    .fa-camera,
    .fa-comment {
      color: $color-secondary;
    }

    .fa-exclamation-circle {
      color: $orange;
    }

    > * {
      height: 100%;
    }
  }

  .reset-pickup-incident-button {
    background-color: $softRed;
    border: none;
  }

  .incident-button {
    position: relative;
    &__menu {
      position: absolute;
      top: 4rem;
      width: 100%;
      border: 1px solid $color-primary;
      background-color: white;
      color: black;
      z-index: 100;
    }

    &__menu-item {
      cursor: pointer;
      padding: 0.5rem;

      &:not(:last-of-type) {
        border-bottom: 1px solid black;
      }

      &:hover,
      &:focus {
        background-color: $color-primary;
        color: white;
      }
    }
  }
}
