.picking-table {
  &__header {
    display: grid;
    grid-template-columns: 1fr 0.75fr 0.75fr 2fr;
    background-color: $color-primary;
    color: white;
    padding: 1rem;
    margin-bottom: 1rem;
  }
}
